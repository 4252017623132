<template>
  <v-container v-if="toolbox" style="margin-bottom: 96px;">
    <h2 style="margin-bottom: 20px">Werkzeugkoffer</h2>
    
    <v-img v-if="toolbox.header_image" class="white--text align-end" height="300px" :src="$ASSET_URL + toolbox.header_image.id + '?width=1280&quality=85'" :alt="toolbox.header_image.title">
      <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{toolbox.header_image.description}}</span>
    </v-img>

    <v-row v-if="toolbox.content" class="pa-2 my-4">
      <v-col cols="12" class="pa-2" v-html="toolbox.content" />
    </v-row>

    <v-row>
      <v-col cols="7">Name</v-col>
      <v-col cols="2">Typ &amp; Größe</v-col>
      <v-col cols="3" style="text-align:right">Download</v-col>
    </v-row>

    <v-row v-for="(item, index) in toolbox.files" :key="index" style="border-top: solid 1px black">
      <v-col cols="7">
        <span class="text-h6">{{item.directus_files_id.title}}</span>
        <br />
        <span class="text-caption">
          aktualisiert am: {{convertDate(item.directus_files_id.modified_on ? item.directus_files_id.modified_on : item.directus_files_id.uploaded_on)}}
        </span>
      </v-col>
      <v-col cols="2">
        <span class="text-h6">{{convertType(item.directus_files_id.type)}}</span>
        <br />
        <span class="text-h6">{{Math.ceil(item.directus_files_id.filesize / 1024 / 1024)}} MB</span>
      </v-col>
      <v-col cols="3" style="text-align:right">
        <v-btn icon :x-small="$vuetify.breakpoint.smAndDown" :href="$DIRECTUS_ASSET_URL + item.directus_files_id.id + '?download'" download target="_blank" class="ma-2">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5365 24.9999H4.46354C3.28016 24.9986 2.14564 24.5279 1.30886 23.6911C0.472086 22.8543 0.00137843 21.7198 0 20.5364L0 18.7499C0.0172026 18.5248 0.118773 18.3145 0.28439 18.1611C0.450007 18.0076 0.667455 17.9224 0.893229 17.9224C1.119 17.9224 1.33645 18.0076 1.50207 18.1611C1.66769 18.3145 1.76926 18.5248 1.78646 18.7499V20.5364C1.78783 21.246 2.07033 21.9261 2.57208 22.4279C3.07383 22.9296 3.75396 23.2121 4.46354 23.2135H20.5365C21.246 23.2121 21.9262 22.9296 22.4279 22.4279C22.9297 21.9261 23.2122 21.246 23.2135 20.5364V18.7499C23.2307 18.5248 23.3323 18.3145 23.4979 18.1611C23.6635 18.0076 23.881 17.9224 24.1068 17.9224C24.3325 17.9224 24.55 18.0076 24.7156 18.1611C24.8812 18.3145 24.9828 18.5248 25 18.7499V20.5364C25 21.7202 24.5297 22.8555 23.6927 23.6926C22.8556 24.5297 21.7203 24.9999 20.5365 24.9999Z" fill="#000"/>
            <path d="M12.5 17.8594C12.3712 17.8597 12.2439 17.8319 12.127 17.7779C12.01 17.724 11.9063 17.6451 11.8229 17.5469L6.46357 11.2969C6.3399 11.115 6.28749 10.8939 6.31632 10.6759C6.34516 10.4578 6.45322 10.2579 6.61992 10.1144C6.78662 9.97089 7.0003 9.89372 7.22024 9.8976C7.44018 9.90148 7.651 9.98615 7.81253 10.1355L12.5 15.5938L17.1875 10.1355C17.3462 9.97222 17.5611 9.87551 17.7885 9.86497C18.0159 9.85444 18.2388 9.93088 18.4118 10.0788C18.5849 10.2266 18.6952 10.4349 18.7203 10.6611C18.7454 10.8874 18.6834 11.1147 18.5469 11.2969L13.1875 17.5469C13.1031 17.6465 12.9976 17.7261 12.8788 17.7801C12.7599 17.8342 12.6306 17.8612 12.5 17.8594Z" fill="#000"/>
            <path d="M12.5 17.8594C12.3826 17.8594 12.2664 17.8362 12.158 17.7911C12.0496 17.746 11.9512 17.6799 11.8684 17.5967C11.7856 17.5134 11.7201 17.4146 11.6757 17.306C11.6312 17.1973 11.6087 17.0809 11.6094 16.9635V0.890625C11.6094 0.654417 11.7032 0.427883 11.8702 0.260858C12.0373 0.0938334 12.2638 0 12.5 0C12.7362 0 12.9628 0.0938334 13.1298 0.260858C13.2968 0.427883 13.3906 0.654417 13.3906 0.890625V16.9635C13.3913 17.0809 13.3688 17.1973 13.3243 17.306C13.2799 17.4146 13.2144 17.5134 13.1316 17.5967C13.0489 17.6799 12.9504 17.746 12.842 17.7911C12.7337 17.8362 12.6174 17.8594 12.5 17.8594Z" fill="#000"/>
          </svg>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Toolbox',
  components: {
  },
  data: () => ({
    toolbox: null,
    known_types:[
                  {type:"application/pdf","display_type":"PDF"},
                  {type:"application/zip","display_type":"Zip"},
                  {type:"image/jpg","display_type":"Bild"},
                  {type:"image/jpeg","display_type":"Bild"},
                  {type:"image/png","display_type":"Bild"},
                  {type:"image/gif","display_type":"Bild"},
                  {type:"image/tiff","display_type":"Bild"},
                ]
  }),
  async created() {
    let temp = await this.$client.items("toolbox").readMany({ fields: ['*.*', 'files.directus_files_id.*']})
    this.toolbox =  temp.data
  },
  methods: {
    convertType: function (type) {
      let temp = this.known_types.find(element => element.type == type)
      
      if (temp) {
        return temp.display_type
      }

      return "???"
    },
    convertDate: function (date) {
      return new Date(Date.parse(date)).toLocaleDateString()
    }
  }

}
</script>