<template>
  <v-container>
    <h1 style="margin-bottom: 20px">Impressum & Datenschutz</h1>
    
    <div v-if="imprint" v-html="imprint.content" />


  </v-container>
</template>

<script>

export default {
  name: 'Imprint',
  components: {
  },
  data: () => ({
    imprint: null
  }),
  async created() {
    let temp = await this.$client.items("imprint").readMany({ fields: ['*.*']})
    this.imprint =  temp.data
  }

}
</script>