<template>
  <v-container>

    <h1>Anregungen und Kontakt</h1>

    <div v-if="feedback" v-html="feedback.einleitung" style="margin: 20px 10px 20px 10px" />
    <hr />
   
    <h2 style="margin: 20px 10px 20px 10px">Ihre Kontaktinformationen</h2>

    <v-form ref="form" v-model="valid" style="background-color: white">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field label="Anrede" v-model="salutation"/>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field label="Vor- und Nachname" v-model="name"/>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field label="eMail" v-model="email" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field label="Telefonnummer" v-model="phone"/>
        </v-col>
        <v-col cols="12">
          <v-textarea label="Anliegen" v-model="concern" required :rules="[v => !!v || 'Angabe wird benötigt']"/>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" v-if="name||email||phone">
          <v-checkbox v-model="contact_permission">
            <template v-slot:label>
              <div>
              Ich willige ein, das im Rahmen des Regionale Open Government Labors Kontakt mit mir aufgenommen werden darf und das meine Kontaktdaten zu diesem Zweck gespeichert werden dürfen. <br />
              Weitere Informationen finden Sie im <a @click.stop="" href="https://gemeindehaus.digital/impressum" target="_blank">Impressum</a>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn :disabled="!valid" color="success" @click="send()">Abschicken</v-btn>
        </v-col>
      </v-row>
    </v-form>

     <h2 style="margin: 20px 10px 20px 10px">Vertraulichkeitsgarantie</h2>
    <div v-if="feedback" v-html="feedback.vertraulichkeitsgarantie" style="margin: 20px 10px 20px 10px" />

  </v-container>
</template>

<script>
  import axios from "axios"

  export default {
    name: 'Feedback',
    components: {
    },
    data: () => ({
      feedback: null,
      valid: false,
      salutation: null,
      name: null,
      email: null,
      phone: null,
      concern: null,
      contact_permission: false,
    }),
    async created() {
      let temp = await this.$client.items("feedback").readMany({ fields: ['*.*']})
      this.feedback =  temp.data
    },
    methods: {
      async send () {
       
        if(!(this.concern && this.contact_permission && (this.email || this.name || this.phone ))) return false
 
        // send to backend, will send to registered recipients
        var sendMessage = axios.create( {baseURL: window.location.protocol + '//' + window.location.hostname});

        await sendMessage.post('/mail/src/mail.php', this.$data).then((result) => {

          this.$refs.form.reset();
          this.$emit('close')
          
        }).catch((error)=>{console.log(error)})

        // clear values
        this.salutation = null
        this.name = null
        this.email = null
        this.phone = null
        this.concern = null
        this.contact_permission = false
      },
    }
  }
</script>
