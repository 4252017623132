<template>
  <v-container>
    <h1>Leichte Sprache</h1>
    <div v-if="easy_language" v-html="easy_language.content"/>
  </v-container>
</template>

<script>

export default {
  name: 'Leichte Sprache',
  components: {
  },
  data: () => ({
    easy_language: null
  }),
  async created() {
    let temp = await this.$client.items("easy_language").readMany({ fields: ['*.*']})
    this.easy_language = temp.data
  }

}
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

 .home-box {
    margin-top: 40px;
    padding: 5px;
    text-align: justify
  }

  .news {
    text-align: justify
  }
  

</style>